<template>
  <div class="marketplace" >
      <div class="title mt-lg-6 mb-lg-11 mb-4" style="cursor:pointer" @click="openPhotographyHtml">Photograph  |  Uptick & IRISnet NFT Photography Competition </div>
    <v-tabs
      background-color="#fff"
      color="#270645"
      height="40px"
      show-arrows
      center-active
      align-with-title
      v-model="activeTab"
      :key="isRefsh"
    >
      <v-tab
        append
        v-for="item in tablist"
        :key="item.value"
        :value="item.value"
        >{{  item.label }}
      </v-tab>
      <SortSelect v-if="!isMobile" :list="selectList" v-model="activeSort" />
       <img  :class="[rotate?'go':'']" class="refresh" src="@/assets/icons/icon_refresh.png"  @click="refresh" />
    </v-tabs>
    <v-tabs-items>
      <!-- <div class="handler">
        <input
         
		   :placeholder="this.$t('creatorSearch')"
          class="search-inpt pa-5"
          v-model="ipt"
        />
        <v-spacer></v-spacer>
        
      </div> -->
      <div v-if="empty" class="empty">{{$t('EmptyHere')}}</div>
      <div class="cards-container">
        <!-- <Cards :cards="cards" />-->
        <CardList  v-if="this.activeTab == 0" :list="list" v-scroll="onScroll" type="sell" :isShowLoading="isShowLoading" />
        <CardList v-else :list="list" v-scroll="onScroll" owerfrom="auction" type="auction"
					:isShowLoading="isShowLoading" />
      </div>

    </v-tabs-items>

	<!-- <div class="right_bottom" v-if="!isMobile&&showRightBottom">
	  <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
	</div> -->
	
	<v-dialog v-model="opencard">
	  <carousel class="otherpage" :list="list" v-if="opencard" @getOpen="OpenCard"></carousel>
	</v-dialog>
  </div>
</template>

<script lang="js">
// import Cards from "@/views/home/components/Cards.vue";
import CardList from "@/components/workCard/CardList.vue";
import SortSelect from '@/components/SortSelect.vue';
import api from "@/api"
import Carousel from "@/components/carousel";
import { mapState } from 'vuex'

let last_time = 0;

export default {
   name: 'Marketplace',
   components: {CardList, SortSelect,Carousel},
   props: {},
   data: function () {
	  console.log('ddddd',sessionStorage.getItem('activeTab')+"___"+sessionStorage.getItem('activeSort'))
      return {
         activeTab:  sessionStorage.getItem('competitionTab') ?  Number(sessionStorage.getItem('competitionTab')) : 0 ,
         tablist: [
			  { value: "0", label: this.$t('headerNavMarketplace')},
				{ value: "3", label: this.$t('headerNavAuctions')},

         ],
         activeSort: sessionStorage.getItem('competitionSort') ? Number(sessionStorage.getItem('competitionSort')): 4,
         selectList: [
            {value: 1, label: this.$t('marketType1')},
            {value: 2, label: this.$t('marketType2')},
            {value: 3, label: this.$t('marketType3')},
            {value: 4, label: this.$t('marketType4')},
            {value: 8, label: this.$t('marketType8')},
            {value: 9, label: this.$t('marketType9')},
            {value: 5, label: this.$t('marketType5')},
            // {value: 6, label: this.$t('marketType6')},
			
			
			
         ],
         showMenu: false,
         list: [],
         pageNumber: 1,
         pageSize: 20,
         totalPage: false,
         isShowLoading: false,
		      opencard: false,
          isRefsh: 0,
          rotate:false,
      showRightBottom: false,
      empty:false
      };
   },
   computed: {
        isMobile: function () {
         return this.$vuetify.breakpoint.mobile;
      },
      handler: function () {
         return {
            ipt: this.searchInput,
            activeTab: this.activeTab,
            activeSort: this.activeSort,
         }
      },
       ...mapState({
          searchInput:  state => state.searchInput,
        })
   },
   watch: {
      handler : {
        handler: function () {
            clearTimeout(last_time);
            last_time = setTimeout(() => {
              console.log("wxl ---  000000000")
              console.log(this.activeTab)
                this.list=[];
                this.pageNumber=1;
                if(this.activeTab == 0)
                {
                  this.getColleectList();
                }else{
                  this.getList();
                }
                
            }, 1000)
        },
        deep: true
      }
   },
   methods: {

      refresh(){
     this.rotate=true;
     setTimeout(() => { this.rotate=false }, 2000);
     this.list=[]
     this.pageNumber=1
       if(this.activeTab == 0)
                {
                  this.getColleectList();
                }else{
                  this.getList();
                }
     
    },
 openPhotographyHtml(){
      console.log("openPhotographyHtml")
       this.$router.push({ name: "Photography" });

},
    // 获取Marketplace列表
      async getColleectList() {
        sessionStorage.setItem('competitionTab',this.activeTab)
		  sessionStorage.setItem('competitionSort',this.activeSort)
         let params = {
           promoId:1,
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            searchKey: this.searchInput,
            sortType: this.activeSort,
            attribute: 5,
         };
		 if(this.activeSort==5){
			params.pageSize=40 
		 }else{
			 params.pageSize=20 
		 }
         console.log(params)
             this.isShowLoading = true;

        return api.home.getGalleryList(params).then(res => {
			 let {list, totalPage} = res.data;
			 this.totalPage = totalPage;
			 this.list = this.list.concat(list);
            this.isShowLoading = false;
			if(this.list.length>0){
				this.showRightBottom =true
			}else{
				this.showRightBottom =false
      }
       if ((this.pageNumber== 1|| this.pageNumber== 0)&& list.length == 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }

		 })
  },
  // 获取拍卖列表
  getList() {
 sessionStorage.setItem('competitionTab',this.activeTab)
		  sessionStorage.setItem('competitionSort',this.activeSort)
				let params = {
          promoId:1,
					attribute: 5,
					pageSize: this.pageSize,
					pageNumber: this.pageNumber,
					searchKey: this.searchInput,
					sortType: this.activeSort,
				};
				this.isShowLoading = true;
				api.home
					.getAuctionList({
						...params,
						pageNumber: this.pageNumber,
						pageSize: this.pageSize,
					})
					.then((response) => {
						const {
							list,
							totalPage
						} = response.data;
						this.pageNumber;
						this.totalPage = totalPage;
						if (this.pageNumber == 1) {
							this.list = list;
						} else {
							this.list = this.list.concat(list);
						}
            this.isShowLoading = false;
             if ((this.pageNumber== 1|| this.pageNumber== 0)&& list.length == 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }

						console.log(list);
					});
			},
      async onScroll(e) {
         const {scrollTop, clientHeight, scrollHeight} =
           e.target.scrollingElement;
         if (scrollTop + clientHeight === scrollHeight && (this.totalPage > this.pageNumber)) {
            this.pageNumber++;
           if(this.activeTab == 0)
                {
                  this.getColleectList();
                }else{
                  this.getList();
                }
         }  
       
      },
	  openCarousel() {
		this.opencard = true;
	  },
	  OpenCard(e) {
	    this.opencard = e;
	  },
      onLangChange() {
        this.tablist= [
			 	  { value: "0", label: this.$t('headerNavMarketplace')},
				{ value: "3", label: this.$t('headerNavAuctions')},
         ];
         this.selectList= [
           {value: 1, label: this.$t('marketType1')},
           {value: 2, label: this.$t('marketType2')},
           {value: 3, label: this.$t('marketType3')},
           {value: 4, label: this.$t('marketType4')},
           {value: 8, label: this.$t('marketType8')},
           {value: 9, label: this.$t('marketType9')},
           {value: 5, label: this.$t('marketType5')},
           {value: 6, label: this.$t('marketType6')},
         ];
         this.isRefsh++;
      }
   },
   mounted() {
     window.eventBus.$on('LangChange', this.onLangChange);
      console.log(8888999,this.$route.query.idx);
	  if(sessionStorage.getItem('competitionTab')){
		  this.activeTab=Number( sessionStorage.getItem('competitionTab'))
	  }else{
		  this.activeTab = this.$route.query.idx;
    }
    console.log(this.activeTab)
	      if(this.activeTab == 0)
                {
                  this.getColleectList();
                }else{
                  this.getList();
                }
	   
   },
};
</script>

<style lang="scss" scoped>
.marketplace {
  ::v-deep.sort-select .rd.v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
  }
  > .v-tabs {
    border-bottom: solid 1px #e3e3e3;

    .v-tab {
      padding: 0;
      margin-right: 50px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 120px;
      letter-spacing: 0px;
      color: #270645;
    }
  }

  .search-inpt {
    width: 600px;
    height: 41px;
    background-color: #f0f0f0;
    border-radius: 20px;
    outline-color: #f0f0f0;
  }

  .handler {
    margin-top: 28px;
    flex: 1;
    display: flex;
    height: 41px;
  }

  .cards-container {
    margin-top: 36px;
  }
  .refresh{
  height: 18px;
  width: 18px;
  margin-top:12px;
  margin-left: 15px;
}
  // .aa{
  //       transition: all 2s;
  //   }
    
    .go{
        transform:rotate(360deg);
        transition: all 2s;
        pointer-events: none;
        user-select: none;
    }
    .title{
        font-family:Helvetica;
        font-size: 25px!important;
        font-weight: normal;
        font-stretch: normal;
        //line-height: 120px;
        letter-spacing: 0px;
        color: #270645;
    }
}

.right_bottom {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 200;

  img {
	display: block;
	width: 59px;
	height: 59px;
	margin-top: 35px;
  }
 
}
 .empty {
	width: 100%;
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
